import React from "react";
import {
  Datagrid,
  List,
  Create,
  SimpleForm,
  TextInput,
  FunctionField,
  NumberInput,
  useTranslate,
  ReferenceArrayInput,
  SelectArrayInput,
  EditButton,
  BooleanInput,
} from "react-admin";
import get from "lodash/get";
import { parse } from "query-string";
import Edit from "./components/Edit";

export const PaymentRenderer = (record) =>
  `${record.name} - ${record.price} ${record.currency}`;

export const DieticianRenderer = (record) =>
  `${record.name} ${record.lastName}`;
export const PaymentList = (props) => {
  const translate = useTranslate();
  return (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} >
      <Datagrid rowClick="edit">
        <FunctionField
          label={translate("resources.Payment.name")}
          render={PaymentRenderer}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
const PaymentFormInput = ({ dietId: propDietId, ...props }) => {
  const dietId = get(props, "record.diets[0].id") || propDietId;
  const redirect = dietId ? `/Diet/${dietId}` : false;
  return (
    <SimpleForm 
      {...props}
      
      redirect={redirect}
      initialValues={{ dietId }}
      warnWhenUnsavedChanges
    >
      <TextInput source="name" />
      <TextInput source="descrption" />
      <TextInput source="type" />
      <NumberInput source="order" label="Gösterilcek Sıra"/>
      <NumberInput
        source="price"
        style={{ display: "inline", float: "left" }}
      />
      <TextInput source="currency" />
      <TextInput source="identifier" />
      <TextInput source="androidIdentifier" />
      
      <BooleanInput source="isSubsription" />
      <BooleanInput source="isSpecial" />
      

        <ReferenceArrayInput source="dieticiansIds" reference="Dietician" label="Diyetisyen">
          <SelectArrayInput optionText={DieticianRenderer}/>
        </ReferenceArrayInput>

           
    </SimpleForm>
  );
};
export const PaymentEdit = (props) => (
  <Edit {...props}>
    <PaymentFormInput />
  </Edit>
);
export const PaymentCreate = (props) => {
  // Read the dayId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { dietId } = parse(props.location.search);
  return (
    <Create {...props}>
      <PaymentFormInput dietId={dietId} />
    </Create>
  );
};
