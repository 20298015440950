import React from "react";
import {
  Datagrid,
  DateField,
  Create,
  List,
  TextField,
  SimpleForm,
  SelectArrayInput,
  EditButton,
  SelectInput,
  FunctionField,
  AutocompleteInput,
  DateTimeInput,
  ReferenceInput,
  ReferenceArrayInput,
  ChipField,
  Filter,
  SearchInput,
  ReferenceField,
} from "react-admin";
import Edit from "./components/Edit";

export const PaymentRenderer = (record) =>
  `${record.name} - ${record.price} ${record.currency}`;

  const UserFilter = (props) => (
    <Filter {...props}>
      <SearchInput source="user.email_contains" alwaysOn />
    </Filter>
  );

export const DieticianRenderer = (record) =>
  `${record.name} ${record.lastName}`;
export const SubscriptionList = (props) => (
  <List {...props} sort={{ field: "expiryDate", order: "DESC" }} filters={<UserFilter />}>
    <Datagrid rowClick="edit">
      <DateField
        source="expiryDate"
        options={{
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          year: "numeric",
        }}
        locales="tr-TR"
      />

      <ReferenceField source="user.id" reference="User" label="Kullanıcı">
        <ChipField source="email" />
      </ReferenceField>

      <ReferenceField
        source="dietician.id"
        reference="Dietician"
        label="Diyetisyen"
      >
        <FunctionField
          label="Name"
          render={(record) => `${record.name} ${record.lastName}`}
        />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);
const SubscriptionFormInput = (props) => (
  <SimpleForm {...props} warnWhenUnsavedChanges>
    <TextField source="id" label="ID" />
    <DateTimeInput source="purchaseDate" />
    <DateTimeInput source="expiryDate" />

    <ReferenceArrayInput
      source="paymentIds"
      reference="Payment"
      label="Ürün"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectArrayInput optionText={PaymentRenderer} />
    </ReferenceArrayInput>

    <ReferenceInput source="dietician.id" reference="Dietician">
      <SelectInput optionText={DieticianRenderer} />
    </ReferenceInput>

    <ReferenceInput
      label="User"
      source="user.id"
      reference="User"
      sort={{ field: "email", order: "ASC" }}
      perPage={10}
      filterToQuery={(searchText) => ({
        OR: [{ email_contains: searchText }],
      })}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
  </SimpleForm>
);
export const SubscriptionEdit = (props) => (
  <Edit {...props}>
    <SubscriptionFormInput redirect="list" />
  </Edit>
);
export const SubscriptionCreate = (props) => (
  <Create {...props}>
    <SubscriptionFormInput redirect="list" />
  </Create>
);
