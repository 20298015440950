import * as User from "./User";
import * as Blog from "./Blog";
import * as Diet from "./Diet";
import * as SubDiet from "./SubDiet";
import * as Dietician from "./Dietician";
import * as Recipe from "./Recipe";
import * as RecipeCategory from "./RecipeCategory";
import * as Day from "./Day";
import * as Repast from "./Repast";
import * as Payment from "./Payment";
import * as RecipeIngredient from "./RecipeIngredient";
import * as Ingredient from "./Ingredient";
import * as ReferralCode from "./ReferralCode";
import * as Subscription from "./Subscription";
import * as Purchase from "./Purchase";
import * as Language from "./language";
import * as Testimonial from "./Testimonial";
import * as AccountDeletionList from "./AccountDeletion";

export default {
  ...User,
  ...Blog,
  ...Diet,
  ...SubDiet,
  ...Dietician,
  ...Recipe,
  ...RecipeCategory,
  ...Day,
  ...Repast,
  ...Payment,
  ...RecipeIngredient,
  ...Ingredient,
  ...ReferralCode,
  ...Subscription,
  ...Purchase,
  ...Language,
  ...Testimonial,
  ...AccountDeletionList
};
