import React from "react";
import { Link } from "react-router-dom";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { withStyles } from "@material-ui/core/styles";
import { Button, useTranslate } from "react-admin";
import { capitalizeFirstLetter } from "../../utils";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const AddButton = ({
  classes,
  record,
  basePath,
  resource,
  relatedResource,
}) => {
  const translate = useTranslate();
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={`/${relatedResource}/create?${capitalizeFirstLetter(resource)}Id=${
        record.id
      }`}
      label={translate("dietmo.addResource", {
        resource: translate(`resources.${relatedResource}.name`),
      })}
    >
      <ChatBubbleIcon />
    </Button>
  );
};

export default withStyles(styles)(AddButton);
