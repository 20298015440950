import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  NumberInput,
  BooleanInput,
  BooleanField
} from "react-admin";
import Edit from "./components/Edit";
import { formatFile } from "../utils";

export const RecipeCategoryList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="nameEn" label="NameEN"/>
      <TextField source="description" />
      <TextField source="descriptionEn" label="Açıklama EN" />
      <BooleanField source="isPremium"  label = "Premium"/>
    </Datagrid>
  </List>
);
const RecipeCategoryFormInput = (props) => (
  <SimpleForm {...props} warnWhenUnsavedChanges>
    <TextInput source="name" />
    <TextInput source="description" fullWidth />

    <TextInput source="nameEn" label="nameEN" />
    <TextInput source="descriptionEn" label="Açıklama EN"  fullWidth />

    <ImageInput format={formatFile} source="imageUrl" accept="image/*">
      <ImageField source="url" />
    </ImageInput>
    <NumberInput source="order" label ="Sıralama"/>
    <BooleanInput source="isOnlist" />
    <BooleanInput source="isPremium"  label = "Premium"/>

  </SimpleForm>
);
export const RecipeCategoryEdit = (props) => (
  <Edit {...props}>
    <RecipeCategoryFormInput />
  </Edit>
);

export const RecipeCategoryCreate = (props) => (
  <Create {...props}>
    <RecipeCategoryFormInput />
  </Create>
);
