import debounce from "lodash/debounce";
import React, { useRef, useCallback } from "react";
import { useInput, FieldTitle } from "ra-core";
import { InputHelperText } from "ra-ui-materialui";
import { FormHelperText, FormControl, InputLabel } from "@material-ui/core";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CKEditorInput = (props) => {
  const {
    fullWidth = true,
    classes: classesOverride,
    configureQuill,
    helperText,
    label,
    source,
    resource,
    variant,
    margin = "dense",
    ...rest
  } = props;

  const {
    id,
    isRequired,
    input: { value, onChange },
    meta: { touched, error },
  } = useInput({ source, ...rest });

  const lastValueChange = useRef(value);

  const onTextChange = useCallback(
    debounce((value) => {
      lastValueChange.current = value;
      onChange(value);
    }, 500),
    []
  );

  return (
    <FormControl
      error={!!(touched && error)}
      fullWidth={fullWidth}
      className="ra-rich-text-input"
      margin={margin}
    >
      {label !== "" && label !== false && (
        <InputLabel shrink htmlFor={id}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
      )}
      <CKEditor
        editor={ClassicEditor}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData();
          onTextChange(data);
        }}
      />
      <FormHelperText
        error={!!error}
        className={!!error ? "ra-rich-text-input-error" : ""}
      >
        <InputHelperText
          error={error}
          helperText={helperText}
          touched={touched}
        />
      </FormHelperText>
    </FormControl>
  );
};

export default CKEditorInput;
