export function formatFile(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}
