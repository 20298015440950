import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  NumberInput
} from "react-admin";
import Edit from "./components/Edit";
import {  Box } from '@material-ui/core';
export const IngredientList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </List>
);
const IngredientFormInput = (props) => (
  <SimpleForm {...props} warnWhenUnsavedChanges >


        <Box display="flex">
            <Box flex={1} mr="0.5em">    <TextInput source="name" /> </Box>
            <Box flex={1} ml="0.5em"> <TextInput source="nameEn" label="nameEN" /></Box>
        </Box>
        <NumberInput source="calory" options={{ maximumFractionDigits: 1 }}/>


        <Box flex={2} mr="1em">
            <Box display="flex">
                <Box flex={1} mr="0.3em"><NumberInput source="artik" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
                <Box flex={1} ml="0.3em"><NumberInput source="water" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
                <Box flex={1} ml="0.3em"><NumberInput source="energy" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
            </Box>

            <Box display="flex">
                <Box flex={1} mr="0.3em"><NumberInput source="protein" fullWidth options={{ maximumFractionDigits: 1 }}/> </Box>
                <Box flex={1} ml="0.3em"><NumberInput source="oil" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
                <Box flex={1} ml="0.3em"><NumberInput source="ch" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
            </Box>

            <Box display="flex">
                <Box flex={1} mr="0.3em"><NumberInput source="pulp" fullWidth options={{ maximumFractionDigits: 1 }}/> </Box>
                <Box flex={1} ml="0.3em"><NumberInput source="cinder" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
                <Box flex={1} ml="0.3em"><NumberInput source="ca" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
            </Box>

            <Box display="flex">
                <Box flex={1} mr="0.3em"><NumberInput source="fe" fullWidth options={{ maximumFractionDigits: 1 }}/> </Box>
                <Box flex={1} ml="0.3em"><NumberInput source="phosphor" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
                <Box flex={1} ml="0.3em"><NumberInput source="potassium" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
            </Box>

            <Box display="flex">
                <Box flex={1} mr="0.3em"><NumberInput source="sodium" fullWidth options={{ maximumFractionDigits: 1 }}/> </Box>
                <Box flex={1} ml="0.3em"><NumberInput source="vitAKaroten" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
                <Box flex={1} ml="0.3em"><NumberInput source="tiamin" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
            </Box>
            
            <Box display="flex">
                <Box flex={1} mr="0.3em"><NumberInput source="ribofl" fullWidth options={{ maximumFractionDigits: 1 }}/> </Box>
                <Box flex={1} ml="0.3em"><NumberInput source="niasin" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
                <Box flex={1} ml="0.3em"><NumberInput source="vitC" fullWidth options={{ maximumFractionDigits: 1 }}/></Box>
            </Box>

        </Box>


  </SimpleForm>
);
export const IngredientEdit = (props) => (
  <Edit {...props}>
    <IngredientFormInput />
  </Edit>
);

export const IngredientCreate = (props) => (
  <Create {...props}>
    <IngredientFormInput />
  </Create>
);
