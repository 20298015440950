import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Create,
  TextInput,
  BooleanField,
  ReferenceField,
  BooleanInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  FormTab,
  EditButton,
  ReferenceManyField,
  DeleteButton,
  useTranslate,
  NumberField,
  SimpleForm,
  ImageInput,
  ImageField,
  ArrayInput,
  NumberInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  SelectArrayInput
} from "react-admin";
import AddButton from "./components/AddButton";
import { PaymentRenderer } from "./Payment";
import get from "lodash/get";
import Edit from "./components/Edit";
import { parse } from "query-string";
import { EditToolbar } from "./Repast";
import { formatFile } from "../utils";

export const DietList = (props) => {
  return (
    <List
      {...props}
      filter={
        props.permissions ? { dietician: { id: props.permissions.id } } : null
      }
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="description" />
        <ReferenceField source="payment.id" reference="Payment">
          <FunctionField render={PaymentRenderer} />
        </ReferenceField>
        <BooleanField source="isActive" />
      </Datagrid>
    </List>
  );
};

const DietFormInput = (props) => {
  let dieticianId = get(props, "record.dietician.id");
  if (!dieticianId && get(props, "permissions.role") === "DIETICIAN") {
    dieticianId = props.permissions.id;
  }
  const redirect = dieticianId ? `/Dietician/${dieticianId}/Diets` : false;
  const translate = useTranslate();
  return (
    <TabbedForm
      {...props}
      initialValues={{ dieticianId }}
      redirect={redirect}
      toolbar={<EditToolbar redirectLink={redirect} />}
      warnWhenUnsavedChanges
    >
      <FormTab
        label={`${translate(`resources.${props.resource}.name`)} ${translate(
          "dietmo.information"
        )}`}
      >
        <TextInput source="name" label="Name" />
        <TextInput
          multiline
          source="description"
          label="Description"
          fullWidth
        />
        <TextInput source="nameEn" label="NameEN" />
        <TextInput
          multiline
          source="descriptionEn"
          label="Açıklama EN"
          fullWidth
        />

        <NumberInput source="order" label="Liste sayfası Sırası" />
        <BooleanInput source="isActive" />
        <BooleanInput source="isPurchaseAvailable" />

        <ReferenceInput source="dietician.id" reference="Dietician">
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput source="payment.id" reference="Payment">
          <SelectInput optionText={PaymentRenderer} />
        </ReferenceInput>
        <ImageInput format={formatFile} source="imageUrl" accept="image/*">
          <ImageField source="url" />
        </ImageInput>
        <ArrayInput source="avoids">
          <SimpleFormIterator>
            <TextInput label={false} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="avoidsEn" label="Kaçınılcaklar EN">
          <SimpleFormIterator>
            <TextInput label={false} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="suggestions">
          <SimpleFormIterator>
            <TextInput label={false} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="suggestionsEn" label="Öneriler EN">
          <SimpleFormIterator>
            <TextInput label={false} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>

        <ReferenceArrayInput
          label="Languages"
          reference="Language"
          source="languagesIds"
        >
          <SelectArrayInput optionText="code" />
        </ReferenceArrayInput>

      </FormTab>
      <FormTab label={translate("resources.SubDiet.name")} path="SubDiets">
        <ReferenceManyField
          addLabel={false}
          reference="SubDiet"
          target="diet.id"
        >
          <Datagrid>
            <NumberField source="minCalory" />
            <NumberField source="maxCalory" />
            <BooleanField source="isActive" />
            <EditButton />
            <DeleteButton redirect="" />
          </Datagrid>
        </ReferenceManyField>
        <AddButton relatedResource="SubDiet" />
      </FormTab>
    </TabbedForm>
  );
};
export const DietEdit = (props) => (
  <Edit {...props}>
    <DietFormInput />
  </Edit>
);
export const DietCreate = (props) => {
  // Read the dayId from the location which is injected by React Router and passed to our component by react-admin automatically
  let { dieticianId } = parse(props.location.search);
  if (!dieticianId && get(props, "permissions.role") === "DIETICIAN") {
    dieticianId = props.permissions.id;
  }
  return (
    <Create {...props}>
      <SimpleForm warnWhenUnsavedChanges initialValues={{ dieticianId }}>
        <TextInput source="name" />
        <TextInput multiline source="description" fullWidth />

        <TextInput source="nameEn" label="nameEN" />
        <TextInput
          multiline
          source="descriptionEn"
          label="Açıklama EN"
          fullWidth
        />

        <BooleanInput source="isActive" />
        <BooleanInput source="isPurchaseAvailable" />
        {!dieticianId && (
          <ReferenceInput source="dietician.id" reference="Dietician">
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <ReferenceInput source="payment.id" reference="Payment">
          <SelectInput optionText={PaymentRenderer} />
        </ReferenceInput>
        <ArrayInput source="avoids">
          <SimpleFormIterator>
            <TextInput label={false} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="avoidsEn" label="Kaçınılcaklar EN">
          <SimpleFormIterator>
            <TextInput label={false} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="suggestions">
          <SimpleFormIterator>
            <TextInput label={false} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="suggestionsEn" label="Öneriler EN">
          <SimpleFormIterator>
            <TextInput label={false} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput
          label="Languages"
          reference="Language"
          source="languagesIds"
        >
          <SelectArrayInput optionText="code" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
