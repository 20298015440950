import React from "react";
import {
  Datagrid,
  Create,
  List,
  TextField,
  SimpleForm,
  EditButton,
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  ChipField,
  ReferenceField,
  DateInput,
  Filter,
  DateField,
  FunctionField,
} from "react-admin";
import Edit from "./components/Edit";

export const PaymentRenderer = (record) =>
  `${record.name} - ${record.price} ${record.currency}`;

export const DieticianRenderer = (record) =>
  `${record.name} ${record.lastName}`;
const DateFilter = (props) => (
  <Filter {...props}>
    <DateInput source="createdAt_gte" label="Min Date" alwaysOn />
    <DateInput source="createdAt_lte" label="Max Date" alwaysOn />

    {/* <ReferenceInput
      source="diet.id"
      reference="Diet"
      filterToQuery={(searchText) => ({ dietician: {id: searchText }})}
      alwaysOn
    >
      <AutocompleteInput optionText="id" />
    </ReferenceInput> */}

   
  </Filter>
);
export const PurchaseList = (props) => (
  <List {...props} filters={<DateFilter />}>
    <Datagrid rowClick="edit">
      <DateField source="createdAt" label="Oluşturulma Tarihi" />

      <ReferenceField source="user.id" reference="User" label="Kullanıcı">
        <ChipField source="email" />
      </ReferenceField>

      <ReferenceField source="diet.id" reference="Diet" label="Diet">
        <ChipField source="name" />
      </ReferenceField>

      <ReferenceField source="diet.id" reference="Diet" label="Diyetisyen">
        <ReferenceField
          source="dietician.id"
          reference="Dietician"
          label="Diyetisyen"
        >
          <FunctionField render={DieticianRenderer} />
        </ReferenceField>
      </ReferenceField>

      <EditButton />
    </Datagrid>
  </List>
);
const PurchaseFormInput = (props) => (
  <SimpleForm {...props} warnWhenUnsavedChanges>
    <TextField source="id" label="ID" />
    <DateField source="createdAt" />

    <ReferenceInput source="diet.id" reference="Diet">
      <SelectInput source="name" />
    </ReferenceInput>

    <ReferenceInput
      label="User"
      source="user.id"
      reference="User"
      sort={{ field: "email", order: "ASC" }}
      perPage={10}
      filterToQuery={(searchText) => ({
        OR: [{ email_contains: searchText }],
      })}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
  </SimpleForm>
);
export const PurchaseEdit = (props) => (
  <Edit {...props}>
    <PurchaseFormInput redirect="list" />
  </Edit>
);
export const PurchaseCreate = (props) => (
  <Create {...props}>
    <PurchaseFormInput redirect="list" />
  </Create>
);
