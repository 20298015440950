import React, { useState, useCallback } from "react";
import {
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { parse } from "query-string";
import get from "lodash/get";
import Edit from "./components/Edit";
import QuickCreateButton from "./components/QuickCreateButton";
import ZAutocompleteInput from "./components/ZAutocompleteInput";

const RecipeIngredientFormInput = ({ recipeId: propRecipeId, ...props }) => {
  const recipeId = get(props, "record.recipe.id") || propRecipeId;
  const redirect = recipeId ? `/Recipe/${recipeId}/ingredients` : false;

  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);
  const handleSuccess = (form, values, data) => {
    form.change("ingredient.id", data.id);
    handleChange();
  };
  return (
    <SimpleForm
      {...props}
      initialValues={recipeId ? { recipeId } : undefined}
      redirect={redirect}
    >
      <TextInput multiline source="quantity" />
      <TextInput multiline source="quantityEn" label="quantityEn" />
      {!recipeId && (
        <ReferenceInput source="recipe.id" reference="Recipe">
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      <ReferenceInput source="unit.id" reference="MeasurementUnit">
        <SelectInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        reference="Ingredient"
        source="ingredient.id"
        filterToQuery={(searchText) => ({
          name_contains: searchText,
        })}
      >
        <ZAutocompleteInput optionText="name" fullWidth />
      </ReferenceInput>
      <QuickCreateButton relatedResource="Ingredient" onSuccess={handleSuccess}>
        <TextInput source="name" fullWidth label="İsim" />
        <TextInput source="nameEn" fullWidth label="İsimEN" />
      </QuickCreateButton>
    </SimpleForm>
  );
};

export const RecipeIngredientEdit = (props) => (
  <Edit {...props}>
    <RecipeIngredientFormInput />
  </Edit>
);

export const RecipeIngredientCreate = (props) => {
  // Read the dayId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { recipeId } = parse(props.location.search);
  return (
    <Create {...props}>
      <RecipeIngredientFormInput recipeId={recipeId} />
    </Create>
  );
};
