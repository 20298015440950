import React from "react";
import { AutocompleteInput } from "react-admin";

export default (props) => (
  <AutocompleteInput
    options={{
      suggestionsContainerProps: {
        style: {
          zIndex: 3000,
        },
      },
    }}
    {...props}
  />
);
