import React, { useState, useCallback } from "react";
import { parse } from "query-string";
import {
  Datagrid,
  List,
  TextField,
  Create,
  ReferenceField,
  DateField,
  ReferenceInput,
  SelectInput,
  ReferenceManyField,
  NumberInput,
  SimpleForm,
  TextInput,
  useTranslate,
  DeleteButton,
  usePermissions,
  useGetManyReference,
  FileInput,
  FileField,
} from "react-admin";
import CKEditorInput from "./components/CKEditorInput";
import { VideoField } from "./components/VideoField";
import { formatFile } from "../utils";
import Edit from "./components/Edit";
import QuickEditButton from "./components/QuickEditButton";
import QuickCreateButton from "./components/QuickCreateButton";
import ZAutocompleteInput from "./components/ZAutocompleteInput";
import { EditToolbar } from "./Repast";
import { max, get, map } from "lodash";
import "./components/ck-content.css";

export const DayList = (props) => {
  const translate = useTranslate();
  return (
    <List sort={{ field: "day", order: "ASC" }} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="day" />
        <ReferenceField
          label={translate("resources.Diet.name")}
          reference="SubDiet"
          source="subDiet.id"
          link=""
        >
          <ReferenceField reference="Diet" source="diet.id" link="">
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};
const DayFormInput = (props) => {
  const subDietId = get(props, "record.subDiet.id");
  const redirect = subDietId ? `/SubDiet/${subDietId}/Days` : false;
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);
  const repasts = useGetManyReference(
    "Repast",
    "day.id",
    get(props, "record.id"),
    { page: 1, perPage: 10 },
    { field: "createdAt", order: "DESC" },
    {},
    "Day"
  );
  const handleSuccess = (form, _values, data) => {
    const repastsIds = form.getState().values.repastsIds;
    form.change(
      "repastsIds",
      repastsIds ? [...repastsIds, data.id] : [data.id]
    );
    handleChange();
  };
  const { permissions } = usePermissions();
  let maxOrder = max(map(get(repasts, "data", []), (d) => d.order)) || 0;
  return (
    <SimpleForm
      {...props}
      initialValues={{ subDietId }}
      redirect={redirect}
      toolbar={<EditToolbar redirectLink={redirect} />}
      warnWhenUnsavedChanges
    >
      <NumberInput source="day" />
      <TextInput source="motivationText" />
      <FileInput format={formatFile} source="url" accept="video/*">
          <VideoField source="url" />
      </FileInput>
      <FileInput format={formatFile} source="srtUrl"  label="SRT ">
          <FileField source="url" title="SRT" />
      </FileInput>
      <FileInput format={formatFile} source="srtUrlEn" label="SRT EN">
          <FileField source="url" title="SRT EN" />
      </FileInput>
      {!subDietId && (
        <ReferenceInput reference="SubDiet" source="subDiet.id">
          <SelectInput source="name" />
        </ReferenceInput>
      )}
      <ReferenceManyField
        addLabel={false}
        reference="Repast"
        target="day"
        fullWidth
        sort={{ field: "order", order: "ASC" }}
        key={version}
      >
        <Datagrid>
          <TextField source="order" />
          <TextField source="name" />
          <QuickEditButton>
            <NumberInput source="order" fullWidth label="Öğün Sırası" />
            <TextInput source="name" fullWidth label="İsim" />
            <CKEditorInput
              source="description"
              multiline
              fullWidth
              helperText = "AÇıklama"
              label="Açıklama"
            />

          <TextField label="Açıklama En" />
            <CKEditorInput
              source="descriptionEn"
              multiline
              fullWidth
              label="Açıklama English"
              helperText = "AÇıklama EN"
            />

            <SelectInput
                  source="type"
                  choices={[
                    { id: "MORNING", name: "Sabah" },
                    { id: "NOON", name: "Öğle" },
                    { id: "EVENING", name: "Akşam" },
                    { id: "SNACK", name: "Atıştırma" },
                    { id: "NONE", name: "Hiç biri" },
                    
                  ]}
              />

            <ReferenceInput
              reference="Recipe"
              source="recipe.id"
              label="Tarif"
              filterToQuery={(searchText) => ({
                AND: [
                  { name_contains: searchText },
                  ...(get(permissions, "role") !== "ADMIN"
                    ? [
                        {
                          OR: [
                            { isPrivate: false },
                            ...(get(permissions, "id")
                              ? [
                                  {
                                    isPrivate: true,
                                    dietician: { id: get(permissions, "id") },
                                  },
                                ]
                              : []),
                          ],
                        },
                      ]
                    : []),
                ],
              })}
              allowEmpty
            >
              <ZAutocompleteInput
                optionText="name"
                fullWidth
                allowEmpty
                emptyText="<Hiçbiri>"
              />
            </ReferenceInput>
          </QuickEditButton>
          <DeleteButton redirect="" />
        </Datagrid>
      </ReferenceManyField>
      <QuickCreateButton
        relatedResource="Repast"
        relatedReference="day.id"
        onSuccess={handleSuccess}
        initialValues={{
          order: maxOrder + 1 + version,
        }}
      >
        <NumberInput source="order" fullWidth label="Öğün Sırası" />
        <TextInput source="name" fullWidth label="İsim" />
        <CKEditorInput
          source="description"
          multiline
          fullWidth
          label="Açıklama"
          helperText = "AÇıklama"
        />


      <TextField label="Açıklama En" />
            <CKEditorInput
              source="descriptionEn"
              multiline
              fullWidth
              label="Açıklama English"
              helperText = "AÇıklama EN"
            />

        <SelectInput
              source="type"
              choices={[
                { id: "MORNING", name: "Sabah" },
                { id: "NOON", name: "Öğle" },
                { id: "EVENING", name: "Akşam" },
                { id: "SNACK", name: "Atıştırma" },
                { id: "NONE", name: "Hiç biri" },
                
              ]}
            />


        <ReferenceInput
          reference="Recipe"
          source="recipe.id"
          label="Tarif"
          filterToQuery={(searchText) => ({
            AND: [
              { name_contains: searchText },
              ...(get(permissions, "role") !== "ADMIN"
                ? [
                    {
                      OR: [
                        { isPrivate: false },
                        ...(get(permissions, "id")
                          ? [
                              {
                                isPrivate: true,
                                dietician: { id: get(permissions, "id") },
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),
            ],
          })}
          allowEmpty
        >
          <ZAutocompleteInput
            optionText="name"
            fullWidth
            allowEmpty
            emptyText="<Hiçbiri>"
          />
        </ReferenceInput>
      </QuickCreateButton>
    </SimpleForm>
  );
};

export const DayEdit = (props) => {
  return (
    <Edit {...props}>
      <DayFormInput />
    </Edit>
  );
};

export const DayCreate = (props) => {
  // Read the dayId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { subDietId } = parse(props.location.search);
  return (
    <Create {...props}>
      <SimpleForm warnWhenUnsavedChanges initialValues={{ subDietId }}>
        <NumberInput source="day" />
        {!subDietId && (
          <ReferenceInput reference="SubDiet" source="subDiet.id">
            <SelectInput source="name" />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  );
};
