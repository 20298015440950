import React from "react";
import {
  Datagrid,
  List,
  Create,
  SimpleForm,
  TextInput,
  TextField,
  ReferenceArrayInput,
  SelectArrayInput,
  EditButton,
} from "react-admin";
import Edit from "./components/Edit";


export const DieticianRenderer = (record) =>
  `${record.name} ${record.lastName}`;

  export const PaymentRenderer = (record) =>
  `${record.name} - ${record.price} ${record.currency}`;


export const ReferralList = (props) => {
  
  return (
    <List {...props} >
      <Datagrid rowClick="edit">
      
        <TextField source="value" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
const ReferralFormInput = (props ) => {

  return (
    <SimpleForm 
      {...props}
      
      warnWhenUnsavedChanges
    >
      <TextInput source="value" />
  
   
        <ReferenceArrayInput source="paymentIds" reference="Payment" label="Payment">
          <SelectArrayInput optionText={PaymentRenderer}/>
        </ReferenceArrayInput>
    </SimpleForm>
  );
};
export const ReferralEdit = (props) => (
  <Edit {...props}>
    <ReferralFormInput />
  </Edit>
);
export const ReferralCreate = (props) => {
  // Read the dayId from the location which is injected by React Router and passed to our component by react-admin automatically
  
  return (
    <Create {...props}>
      <ReferralFormInput />
    </Create>
  );
};
