import React, { useState } from "react";
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  useTranslate,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import { useForm } from "react-final-form";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function QuickCreateButton(props) {
  const { record, relatedResource, children, onSuccess, initialValues } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate(relatedResource);
  const notify = useNotify();
  const translate = useTranslate();
  const form = useForm();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      {
        payload: {
          data: {
            ...values,
            dayId: record.id,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          onSuccess(form, values, data);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button
        onClick={handleClick}
        label={`${translate(`resources.${relatedResource}.name`)} ${translate(
          "ra.action.create"
        )}`}
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={`${translate(
          `resources.${relatedResource}.name`
        )} ${translate("ra.action.create")}`}
      >
        <DialogTitle>
          {translate(`resources.${relatedResource}.name`)}{" "}
          {translate("ra.action.create")}
        </DialogTitle>

        <FormWithRedirect
          record={initialValues}
          resource={relatedResource}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>{children}</DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default QuickCreateButton;
