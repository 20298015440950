import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import buildGraphcoolProvider from "ra-data-graphcool";
import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import addUploadCapabilities from "./addUploadCapabilities";
import authProvider from "./authProvider";
import { API_URL, AUTH_TOKEN } from "./constants";
import Resources from "./resources";
import polyglotI18nProvider from "ra-i18n-polyglot";
import turkishMessages from "ra-language-turkish";
import { domainMessages } from "./i18n";
import englishMessages from "ra-language-english";
import Layout from "./Layout";
import removeUnchangedFields from "./removeUnchangedFields";
import dieticianToUser from "./dieticianToUser";
import { merge } from "lodash";

const messages = {
  tr: merge(turkishMessages, domainMessages.tr),
  en: merge(englishMessages, domainMessages.en),
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "tr");

const httpLink = createHttpLink({
  uri: API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
class App extends Component {
  constructor() {
    super();
    this.state = { dataProvider: null };
  }
  componentDidMount() {
    buildGraphcoolProvider({
      client: client,
    }).then((dataProvider) => this.setState({ dataProvider }));
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <div>Loading</div>;
    }

    return (
      <Admin
        dataProvider={dieticianToUser(
          removeUnchangedFields(addUploadCapabilities(dataProvider))
        )}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        layout={Layout}
        title="Dietmo Admin"
      >
        {(permissions) => [
          <Resource
            name="Dietician"
            list={Resources.DieticianList}
            edit={Resources.DieticianEdit}
          />,
          <Resource
            name="User"
            list={permissions.role === "ADMIN" ? Resources.UserList : null}
            edit={permissions.role === "ADMIN" ? Resources.UserEdit : null}
          />,
          <Resource
            name="Diet"
            list={permissions.role === "DIETICIAN" ? Resources.DietList : null}
            edit={Resources.DietEdit}
            create={Resources.DietCreate}
          />,
          <Resource
            name="SubDiet"
            edit={Resources.SubDietEdit}
            create={Resources.SubDietCreate}
          />,
          <Resource
            name="Blog"
            list={Resources.BlogList}
            edit={Resources.BlogEdit}
            create={Resources.BlogCreate}
          />,
          <Resource
            name="Testimonial"
            list={Resources.TestimonialList}
            edit={Resources.TestimonialEdit}
            create={Resources.TestimonialCreate}
          />,
          <Resource
            name="Recipe"
            list={Resources.RecipeList}
            edit={Resources.RecipeEdit}
            create={Resources.RecipeCreate}
          />,
          <Resource
            name="RecipeCategory"
            options={{ label: "Recipe Categories" }}
            list={Resources.RecipeCategoryList}
            edit={Resources.RecipeCategoryEdit}
            create={Resources.RecipeCategoryCreate}
          />,
          <Resource
            name="Ingredient"
            list={Resources.IngredientList}
            edit={Resources.IngredientEdit}
            create={Resources.IngredientCreate}
          />,
          <Resource
            name="Day"
            // list={Resources.DayList}
            edit={Resources.DayEdit}
            create={Resources.DayCreate}
          />,
          <Resource
            name="Repast"
            // list={Resources.RepastList}
            edit={Resources.RepastEdit}
            create={Resources.RepastCreate}
          />,
          <Resource
            name="Payment"
            list={permissions.role === "ADMIN" ? Resources.PaymentList : null}
            edit={permissions.role === "ADMIN" ? Resources.PaymentEdit : null}
            create={
              permissions.role === "ADMIN" ? Resources.PaymentCreate : null
            }
          />,
          <Resource
            name="RecipeIngredient"
            edit={Resources.RecipeIngredientEdit}
            create={Resources.RecipeIngredientCreate}
          />,
          <Resource
            name="ReferralCode"
            list={permissions.role === "ADMIN" ? Resources.ReferralList : null}
            edit={permissions.role === "ADMIN" ? Resources.ReferralEdit : null}
            create={
              permissions.role === "ADMIN" ? Resources.ReferralCreate : null
            }
          />,
          <Resource
            name="Subscription"
            list={
              permissions.role === "ADMIN" ? Resources.SubscriptionList : null
            }
            edit={
              permissions.role === "ADMIN" ? Resources.SubscriptionEdit : null
            }
            create={
              permissions.role === "ADMIN" ? Resources.SubscriptionCreate : null
            }
          />,
          <Resource
            name="Language"
            list={Resources.LanguageList}
            edit={Resources.LanguageEdit}
            create={Resources.LanguageCreate}
          />,
          <Resource
          name="AccountDeletion"
          options={{ label: "Account Deletion" }}
          list={Resources.AccountDeletionList}
        />,
          <Resource
            name="Purchase"
            list={permissions.role === "ADMIN" ? Resources.PurchaseList : null}
            edit={permissions.role === "ADMIN" ? Resources.PurchaseEdit : null}
            create={
              permissions.role === "ADMIN" ? Resources.PurchaseCreate : null
            }
          />,
          <Resource name="MeasurementUnit" />,
        ]}
      </Admin>
    );
  }
}

export default App;
