const fields = {
  tr: {
    name: "İsim",
    lastName: "Soyisim",
    email: "E-posta",
    image: "Resim",
    imageUrl: "Resim",
    profileImageUrl: "Resim",
    createdAt: "Oluşturulma tarihi",
    biography: "Biyografi",
    gender: "Cinsiyet",
    role: "Rol",
    targetWeight: "Hedef Ağırlık",
    weight: "Ağırlık",
    age: "Yaş",
    height: "Boy",
    order: "Öğün Sırası",
    title: "Başlık",
    description: "Açıklama",
    descriptionEn: "AçıklamaEn",
    "description.id": "Açıklama",
    isActive: "Aktif",
    payment: "Ödeme",
    "payment.id": "Ödeme",
    dietician: "Diyetisyen",
    "dietician.id": "Diyetisyen",
    diet: "Diyet",
    "diet.id": "Diyet",
    dietsIds: "Diyetler",
    content: "İçerik",
    isHomePage: "Anasayfada",
    author: "Yazar",
    "author.id": "Yazar",
    avoids: "Kaçınılacaklar",
    suggestions: "Tavsiyeler",
    minCalory: "Min Kalori",
    maxCalory: "Max Kalori",
    day: "gün",
    "day.id": "gün",
    categoriesIds: "Kategoriler",
    color: "Renk",
    preparation: "Hazırlanışı",
    url: "Video",
    "recipe.id": "Tarif",
    "subDiet.id": "Alt Diyet",
    "mealRecipe.id": "Tarif",
    "repast.id": "Öğün",
    recipe: "Tarif",
    identifier: "Tanımlayıcı",
    price: "Ücret",
    currency: "Para Birimi",
    paymentsIds: "Ödemeler",
    "ingredient.id": "Malzeme",
    quantity: "Miktar",
    "unit.id": "Birim",
    isPrivate: "Kişiye özel",
    serving: "Porsiyon",
    isPurchaseAvailable: "Satın Alma",
  },
};

export const domainMessages = {
  tr: {
    app: {
      page: {
        loading: "Yükleniyor",
      },
      message: {
        loading: "Sayfa yükleniyor",
      },
    },
    ra: {
      page: {
        empty: "%{name} yok.",
        invite: "Oluşturmak ister misin?",
      },
      message: {
        unsaved_changes:
          "Yaptığınız değişikliklerin bazıları kaydedilmedi. Onları yoksaymak istediğinizden emin misiniz?",
      },
      action: {
        unselect: "Seçimi kaldır",
      },
    },
    resources: {
      User: {
        name: "Kullanıcı",
        fields: { ...fields.tr, order: "Uzman sırası" },
      },
      Dietician: {
        name: "Diyetisyen",
        fields: { ...fields.tr, order: "Uzman sırası" },
      },
      Diet: {
        name: "Diyet",
        fields: fields.tr,
      },
      SubDiet: {
        name: "Alt Diyet",
        fields: fields.tr,
      },
      Blog: {
        name: "Blog",
        fields: fields.tr,
      },
      Recipe: {
        name: "Tarif",
        fields: fields.tr,
      },
      RecipeCategory: {
        name: "Tarif Kategorisi",
        fields: fields.tr,
      },
      Day: {
        name: "Gün",
        fields: fields.tr,
      },
      Repast: {
        name: "Öğün",
        fields: fields.tr,
      },
      Payment: {
        name: "Ödeme",
        fields: fields.tr,
      },
      RecipeIngredient: {
        name: "Malzeme",
        fields: fields.tr,
      },
      Ingredient: {
        name: "Malzeme",
        fields: fields.tr,
      },
    },
    dietmo: {
      english: "İngilizce",
      turkish: "Türkçe",
      information: "Bilgisi",
      role: {
        admin: "Admin",
        guest: "Misafir",
        moderator: "Moderatör",
        dietician: "Diyetisyen",
      },
      addResource: "%{resource} Ekle",
    },
  },
  en: {
    dietmo: {
      english: "English",
      turkish: "Turkish",
      information: "Information",
      addResource: "Add a %{resource}",
    },
  },
};
