import { v4 as uuidv4 } from 'uuid';
const AWS = require('aws-sdk');


const BUCKET_NAME = 'bidiyet-bucket';

const s3 = new AWS.S3({
  region: 'eu-central-1',
  accessKeyId: 'AKIAVEZ26VIRPQZTJQHG',
  secretAccessKey: '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'
});


const upload = async (file) => {


  var key = uuidv4() + file.name
  const params = {
    Bucket: BUCKET_NAME,
    Key: key, // File name you want to save as in S3
    Body: file
  };

  // Uploading files to the bucket
  const res = await s3.upload(params).promise();

  return res.Location
  
};



/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadCapabilities = (requestHandler) => (type, resource, params) => {
  if (type === "UPDATE" || type === "CREATE") {
    const uploadFields = Object.keys(params.data)
      .filter((key) => key.match(/(?:imageUrl|srtUrlEn|srtUrl|profileImageUrl|url)/))
      // filter out empty or already existing files from uploading.
      .filter((k) => params.data[k] && params.data[k].rawFile);
    let files = uploadFields.map((field) => ({ [field]: params.data[field] }));
    if (files.length) {
      // use the file endpoint
      return Promise.all(
        files.map(async (file, index) => {
          let u = await upload(file[uploadFields[index]].rawFile);
          return u;
        })
      ).then((response) => {
        const data = uploadFields.reduce(
          (prev, curr, index) => ({
            ...prev,
            [curr]: response[index],
          }),
          {}
        );
        return requestHandler(type, resource, {
          ...params,
          data: { ...params.data, ...data },
        });
      });
    }
  }

  return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
