const dieticianToUser = (requestHandler) => (type, resource, params) => {
  if (resource === "Dietician") {
    return requestHandler(type, "User", {
      ...params,
      filter: { ...params.filter, role: "DIETICIAN" },
    });
  }

  return requestHandler(type, resource, params);
};

export default dieticianToUser;
