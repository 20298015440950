import { transform, isEqual, isEqualWith, isObject, isArray } from "lodash";

const IsUndefinedOrNullOrEqual = (value1, value2) =>
  (value1 === null && value2 === undefined) ||
  (value1 === undefined && value2 === null) ||
  isEqual(value1, value2);

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqualWith(value, base[key], IsUndefinedOrNullOrEqual)) {
      result[key] =
        isObject(value) &&
        isObject(base[key]) &&
        !isArray(value) &&
        !isArray(base[key])
          ? difference(value, base[key])
          : value;
    }
  });
}
const removeUnchangedFields = (requestHandler) => (type, resource, params) => {
  if (type === "UPDATE") {
    return requestHandler(type, resource, {
      ...params,
      data: {
        id: params.data.id,
        ...difference(params.data, params.previousData),
      },
    });
  }

  return requestHandler(type, resource, params);
};

export default removeUnchangedFields;
