import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ChipField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  NumberInput,
  SaveButton,
  Toolbar,
  DeleteButton,
  AutocompleteInput,
} from "react-admin";
import get from "lodash/get";
import CKEditorInput from "./components/CKEditorInput";
import AddButton from "./components/AddButton";
import Edit from "./components/Edit";
import { parse } from "query-string";
import BackButton from "./components/BackButton";
import { Spacer } from "./components/Spacer";
import { RichTextField } from "react-admin";
import "./components/ck-content.css";

export const RepastList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <RichTextField source="description" className="ck-content" />
      <ReferenceField reference="Recipe" source="recipe.id">
        <ChipField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const EditToolbar = ({ redirectLink, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <BackButton redirect={redirectLink} />
    <Spacer />
    <DeleteButton redirect={redirectLink} />
  </Toolbar>
);
const RepastFormInput = (props) => {
  const dayId = get(props, "record.day.id");
  const redirect = dayId ? `/Day/${dayId}` : false;
  return (
    <SimpleForm
      {...props}
      redirect={redirect}
      initialValues={dayId ? { dayId } : undefined}
      toolbar={<EditToolbar redirectLink={redirect} />}
      warnWhenUnsavedChanges
    >
      <TextInput source="name" />
      <TextInput source="nameEn" label="nameEN"/>
      <NumberInput source="order" />
      <CKEditorInput multiline source="description" fullWidth />
      {!dayId && (
        <ReferenceInput reference="Day" source="day.id">
          <SelectInput optionText="day" />
        </ReferenceInput>
      )}
      <ReferenceInput
        reference="Recipe"
        source="recipe.id"
        filterToQuery={(searchText) => ({ name_contains: searchText })}
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <SelectInput
      source="type"
      choices={[
        { id: "MORNING", name: "Sabah" },
        { id: "NOON", name: "Öğle" },
        { id: "EVENING", name: "Akşam" },
        { id: "SNACK", name: "Atıştırma" },
        { id: "NONE", name: "Hiç biri" },
        
      ]}
    />
      <AddButton relatedResource="Recipe" />
    </SimpleForm>
  );
};
export const RepastEdit = (props) => {
  return (
    <Edit {...props}>
      <RepastFormInput />
    </Edit>
  );
};
export const RepastCreate = (props) => {
  // Read the dayId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { dayId } = parse(props.location.search);
  const redirect = dayId ? `/Day/${dayId}` : undefined;
  return (
    <Create {...props}>
      <SimpleForm
        initialValues={dayId ? { dayId } : undefined}
        toolbar={<EditToolbar redirectLink={redirect} />}
        warnWhenUnsavedChanges
      >
        <NumberInput source="order" />
        <TextInput source="name" />
        <TextInput source="nameEn"  label="nameEN" />
        <CKEditorInput multiline source="description" fullWidth />
        {!dayId && (
          <ReferenceInput reference="Day" source="day.id">
            <SelectInput optionText="day" />
          </ReferenceInput>
        )}
      <SelectInput
          source="type"
          choices={[
            { id: "MORNING", name: "Sabah" },
            { id: "NOON", name: "Öğle" },
            { id: "EVENING", name: "Akşam" },
            { id: "SNACK", name: "Atıştırma" },
            { id: "NONE", name: "Hiç biri" },
            
          ]}
      />
      </SimpleForm>
    </Create>
  );
};
