import React from "react";
import {
  Datagrid,
  List,
  Create,
  ChipField,
  BooleanField,
  ReferenceField,
  BooleanInput,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  DateField,
  NumberInput,
  ReferenceManyField,
  NumberField,
  DeleteButton,
  EditButton,
  useTranslate,
  SimpleForm,
} from "react-admin";
import InstantAddButton from "./components/InstantAddButton";
import { parse } from "query-string";
import get from "lodash/get";
import Edit from "./components/Edit";
import { EditToolbar } from "./Repast";

export const SubDietList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField reference="Diet" source="diet.id">
        <ChipField source="name" />
      </ReferenceField>
      <BooleanField source="isActive" />
    </Datagrid>
  </List>
);
const SubDietFormInput = ({ dietId: propDietId, ...props }) => {
  const dietId = get(props, "record.diet.id") || propDietId;
  const redirect = dietId ? `/Diet/${dietId}/SubDiets` : false;
  const translate = useTranslate();
  return (
    <TabbedForm
      {...props}
      redirect={redirect}
      initialValues={{ dietId }}
      toolbar={<EditToolbar redirectLink={redirect} />}
      warnWhenUnsavedChanges
    >
      <FormTab
        label={`${translate(`resources.${props.resource}.name`)} ${translate(
          "dietmo.information"
        )}`}
      >
        <ReferenceInput
          label={translate("resources.Diet.name")}
          source="diet.id"
          reference="Diet"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <BooleanInput source="isActive" />
        <NumberInput
          source="minCalory"
          style={{ display: "inline", float: "left" }}
        />
        <NumberInput source="maxCalory" />
      </FormTab>
      <FormTab label={translate("resources.Day.name")} path="Days">
        <ReferenceManyField
          addLabel={false}
          reference="Day"
          target="subDiet.id"
          sort={{ field: "day", order: "ASC" }}
          perPage={100}
        >
          <Datagrid>
            <DateField source="createdAt" />
            <NumberField source="day" />
            <EditButton />
            <DeleteButton redirect="" />
          </Datagrid>
        </ReferenceManyField>
        <InstantAddButton relatedResource="Day" />
      </FormTab>
    </TabbedForm>
  );
};

export const SubDietEdit = (props) => (
  <Edit {...props}>
    <SubDietFormInput />
  </Edit>
);

export const SubDietCreate = (props) => {
  const translate = useTranslate();
  // Read the dayId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { dietId } = parse(props.location.search);
  return (
    <Create {...props}>
      <SimpleForm initialValues={{ dietId }} warnWhenUnsavedChanges>
        {!dietId && (
          <ReferenceInput
            label={translate("resources.Diet.name")}
            source="diet.id"
            reference="Diet"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}

        <BooleanInput source="isActive" />
        <NumberInput
          source="minCalory"
          style={{ display: "inline", float: "left" }}
        />
        <NumberInput source="maxCalory" />
      </SimpleForm>
    </Create>
  );
};
