import {
  AUTH_LOGIN,
  AUTH_CHECK,
  AUTH_LOGOUT,
  AUTH_GET_PERMISSIONS,
} from "react-admin";
import { API_URL, AUTH_TOKEN } from "./constants";

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(API_URL, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({
        query: `
          mutation{
            authenticateUser(email: "${username}", password: "${password}", role: "ADMIN") {
              id
              token
              role
            }
          }
        `,
      }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw new Error(errors[0].functionError);
        }
        localStorage.setItem(AUTH_TOKEN, data.authenticateUser.token);
        localStorage.setItem(
          "permissions",
          JSON.stringify({
            role: data.authenticateUser.role,
            id: data.authenticateUser.id,
          })
        );
        return Promise.resolve();
      });
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem("permissions");
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem(AUTH_TOKEN)
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/login " });
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  }
};
