import React from "react";
import {
  Datagrid,
  List,
  ReferenceField,
  FunctionField
} from "react-admin";
export const UserRenderer = (record) =>
  `${record.email}`;

export const AccountDeletionList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField source="user.id" reference="User">
          <FunctionField render={UserRenderer} />
        </ReferenceField>
    </Datagrid>
  </List>
);
