import React from "react";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  TextInput,
  ImageInput,
  ImageField,
  ReferenceArrayInput,
  SelectArrayInput,
  useTranslate,
  TabbedForm,
  FormTab,
  ReferenceField,
  FunctionField,
  BooleanField,
  ReferenceManyField,
  EditButton,
  DeleteButton,
  DateField,
  NumberInput,
} from "react-admin";
import Edit from "./components/Edit";
import { formatFile } from "../utils";
import { PaymentRenderer } from "./Payment";
import AddButton from "./components/AddButton";
import get from "lodash/get";

export const DieticianList = (props) => (
  <List
    {...props}
    filter={
      get(props, "permissions.role") !== "ADMIN"
        ? { id: get(props, "permissions.id") }
        : undefined
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <TextField source="title" />
    </Datagrid>
  </List>
);
const DieticianFormInput = (props) => {
  const translate = useTranslate();
  return (
    <TabbedForm {...props} warnWhenUnsavedChanges>
      <FormTab
        label={`${translate(`resources.${props.resource}.name`)} ${translate(
          "dietmo.information"
        )}`}
      >
        <TextInput source="name" />
        <TextInput source="lastName" />
        <TextInput source="email" type="email" />
        <ImageInput
          format={formatFile}
          source="profileImageUrl"
          accept="image/*"
        >
          <ImageField source="url" />
        </ImageInput>
        <TextInput source="title" />
        <TextInput source="titleEn" label= "TitleEn" />
        <ReferenceArrayInput source="paymentsIds" reference="Payment">
          <SelectArrayInput optionText={PaymentRenderer} />
        </ReferenceArrayInput>
        <NumberInput source="order" />
        <ReferenceArrayInput
      label="Languages"
      reference="Language"
      source="languagesIds"
    >
      <SelectArrayInput optionText="code" />
    </ReferenceArrayInput>
      </FormTab>
      <FormTab label={`${translate(`resources.Diet.name`)}`} path="Diets">
        <ReferenceManyField
          addLabel={false}
          reference="Diet"
          target="dietician.id"
          fullWidth
        >
          <Datagrid>
            <TextField source="name" />
            <TextField source="description" />

            <TextField source="nameEn" label="nameEN"/>
            <TextField source="descriptionEn" label="Açıklama EN"/>

            <ReferenceField source="payment.id" reference="Payment">
              <FunctionField render={PaymentRenderer} />
            </ReferenceField>
            <BooleanField source="isActive" />
            <EditButton />
            <DeleteButton />
          </Datagrid>
        </ReferenceManyField>
        <AddButton relatedResource="Diet" />
      </FormTab>
      <FormTab label={`${translate(`resources.Blog.name`)}`} path="Blogs">
        <ReferenceManyField
          addLabel={false}
          reference="Blog"
          target="dietician.id"
          fullWidth
        >
          <Datagrid>
            <TextField source="title" />
            <TextField source="titleEn" label="Title EN"/>
            <BooleanField source="isActive" />
            <BooleanField source="isHomePage" />
            <DateField source="createdAt" />
            <EditButton />
            <DeleteButton />
          </Datagrid>
        </ReferenceManyField>
        <AddButton relatedResource="Blog" />
      </FormTab>
    </TabbedForm>
  );
};
export const DieticianEdit = (props) => (
  <Edit {...props}>
    <DieticianFormInput />
  </Edit>
);
