import React, { useState } from "react";
import {
  Button,
  SaveButton,
  useUpdate,
  useNotify,
  FormWithRedirect,
  useGetOne,
  useTranslate,
} from "react-admin";
import IconContentEdit from "@material-ui/icons/Edit";
import IconCancel from "@material-ui/icons/Cancel";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function QuickEditButton({ record, resource, children }) {
  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading }] = useUpdate(resource, record.id);
  const notify = useNotify();
  const { data } = useGetOne(resource, record.id);
  const translate = useTranslate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    update(
      { payload: { data: values, previousData: record } },
      {
        onSuccess: () => {
          setShowDialog(false);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.edit">
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={`${translate(`resources.${resource}.name`)} ${translate(
          "ra.action.edit"
        )}`}
      >
        <DialogTitle>
          {translate(`resources.${resource}.name`)}{" "}
          {translate("ra.action.edit")}
        </DialogTitle>

        <FormWithRedirect
          record={data}
          resource={resource}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>{children}</DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default QuickEditButton;
