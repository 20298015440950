import React from "react";
import {
  Datagrid,
  DateField,
  EmailField,
  List,
  TextField,
  SimpleForm,
  NumberInput,
  TextInput,
  DateInput,
  ImageInput,
  ImageField,
  SelectInput,
  Filter,
  SearchInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import Edit from "./components/Edit";
import { formatFile } from "../utils";

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="email_contains" alwaysOn />
  </Filter>
);

export const UserList = (props) => (
  <List {...props} filters={<UserFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <TextField source="role" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);
const UserFormInput = (props) => (
  <SimpleForm {...props} warnWhenUnsavedChanges>
    <TextInput source="name" />
    <TextInput source="lastName" />
    <TextInput source="email" type="email" />
    <ImageInput format={formatFile} source="profileImageUrl" accept="image/*">
      <ImageField source="url" />
    </ImageInput>
    <DateInput source="createdAt" />
    <TextInput source="biography" />
    <TextInput source="gender" />
    <SelectInput
      source="role"
      choices={[
        { id: "ADMIN", name: "dietmo.role.admin" },
        { id: "GUEST", name: "dietmo.role.guest" },
        // { id: "MODERATOR", name: "dietmo.role.moderator" },
        { id: "DIETICIAN", name: "dietmo.role.dietician" },
      ]}
    />
    <TextInput source="targetWeight" />
    <TextInput source="weight" />
    <NumberInput source="age" />
    <NumberInput source="height" />
    <NumberInput source="order" />
    <ReferenceArrayInput
      label="Languages"
      reference="Language"
      source="languagesIds"
    >
      <SelectArrayInput optionText="code" />
    </ReferenceArrayInput>
  </SimpleForm>
);
export const UserEdit = (props) => (
  <Edit {...props}>
    <UserFormInput />
  </Edit>
);
