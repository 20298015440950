import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

const styles = {
  list: {
    display: "flex",
    listStyleType: "none",
  },
  image: {
    margin: "0.5rem",
    maxHeight: "10rem",
  },
};

export const VideoField = ({ elStyle = {}, record, source, src, title }) => {
  const sourceValue = get(record, source, record);
  if (!sourceValue) {
    return <div />;
  }

  if (Array.isArray(sourceValue)) {
    const style = {
      ...styles.list,
      ...elStyle,
    };
    return (
      <ul style={style}>
        {sourceValue.map((file, index) => {
          const titleValue = get(file, title) || title;
          const srcValue = get(file, src) || src;

          return (
            <li key={index}>
              <video
                key={srcValue}
                title={titleValue}
                style={styles.image}
                controls
              >
                <source src={srcValue} type="video/mp4" />
                <p>
                  Your browser doesn't support HTML5 video. Here is a{" "}
                  <a href={srcValue}>link to the video</a> instead.
                </p>
              </video>
            </li>
          );
        })}
      </ul>
    );
  }

  const titleValue = get(record, title) || title;

  return (
    <div style={elStyle}>
      <video key={sourceValue} title={titleValue} style={styles.image} controls>
        <source src={sourceValue} type="video/mp4" />
        <p>
          Your browser doesn't support HTML5 video. Here is a{" "}
          <a href={sourceValue}>link to the video</a> instead.
        </p>
      </video>
    </div>
  );
};

VideoField.propTypes = {
  elStyle: PropTypes.object,
  record: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  source: PropTypes.string,
  title: PropTypes.string,
};

export default VideoField;
