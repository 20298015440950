import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ChipField,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  ImageInput,
  ImageField,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import CKEditorInput from "./components/CKEditorInput";
import Edit from "./components/Edit";
import { formatFile } from "../utils";
import get from "lodash/get";
import { EditToolbar } from "./Repast";
import { parse } from "query-string";

export const BlogList = (props) => {
  return (
    <List
      {...props}
      filter={
        get(props, "permissions.role") !== "ADMIN"
          ? { dietician: { id: get(props, "permissions.id") } }
          : undefined
      }
    >
      <Datagrid rowClick="edit">
        <TextField source="title" label="Title" />
        <ReferenceField source="dietician.id" reference="Dietician">
          <ChipField source="name" />
        </ReferenceField>
        <BooleanField source="isActive" />
        <BooleanField source="isHomePage" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};
const BlogFormInput = ({ dieticianId: propDieticianId, ...props }) => {
  let dieticianId = get(props, "record.dietician.id") || propDieticianId;
  if (!dieticianId && get(props, "permissions.role") === "DIETICIAN") {
    dieticianId = props.permissions.id;
  }
  const redirect = dieticianId ? `/Dietician/${dieticianId}/Blogs` : false;
  return (
    <SimpleForm
      {...props}
      initialValues={{ dieticianId }}
      redirect={redirect}
      toolbar={<EditToolbar redirectLink={redirect} />}
      warnWhenUnsavedChanges
    >
      <TextInput source="title" label="Title" />
      <TextInput source="titleEn" label="Title EN" />

      <ReferenceInput source="dietician.id" reference="Dietician">
        <SelectInput optionText="name" />
      </ReferenceInput>

      <CKEditorInput source="content" label="Content" />
      <CKEditorInput source="contentEn" label="İçerik EN" />
      <BooleanInput source="isActive" />
      <BooleanInput source="isHomePage" />

      <ReferenceArrayInput
          label="Languages"
          reference="Language"
          source="languagesIds"
        >
          <SelectArrayInput optionText="code" />
        </ReferenceArrayInput>

      <ImageInput format={formatFile} source="imageUrl" accept="image/*">
        <ImageField source="url" />
      </ImageInput>
    </SimpleForm>
  );
};
export const BlogEdit = (props) => (
  <Edit {...props}>
    <BlogFormInput />
  </Edit>
);

export const BlogCreate = (props) => {
  // Read the dayId from the location which is injected by React Router and passed to our component by react-admin automatically
  const { dieticianId } = parse(props.location.search);
  return (
    <Create {...props}>
      <BlogFormInput dieticianId={dieticianId} />
    </Create>
  );
};
