import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Create,
  TabbedForm,
  TextInput,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  SelectArrayInput,
  ReferenceArrayInput,
  BooleanInput,
  FileInput,
  ImageInput,
  ImageField,
  FormTab,
  useTranslate,
  ReferenceManyField,
  ReferenceField,
  EditButton,
  DeleteButton,
  SimpleForm,
  Filter,
  SearchInput,
  NumberInput,
  NumberField,
  FileField,
} from "react-admin";
import { VideoField } from "./components/VideoField";
import CKEditorInput from "./components/CKEditorInput";
import { ColorField, ColorInput } from "react-admin-color-input";
import get from "lodash/get";
import AddButton from "./components/AddButton";
import Edit from "./components/Edit";
import { formatFile } from "../utils";

const RecipeFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name_contains" alwaysOn />
  </Filter>
);

export const RecipeList = (props) => {
  return (
    <List
      {...props}
      filter={
        get(props, "permissions.role") !== "ADMIN"
          ? {
              OR: [
                { isPrivate: false },
                ...(get(props, "permissions.id")
                  ? [
                      {
                        isPrivate: true,
                        dietician: { id: get(props, "permissions.id") },
                      },
                    ]
                  : []),
              ],
            }
          : undefined
      }
      filters={<RecipeFilter />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceArrayField reference="RecipeCategory" source="categoriesIds">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <NumberField source="serving" />
        <ColorField source="color" />
      </Datagrid>
    </List>
  );
};
const RecipeFormInput = (props) => {
  const translate = useTranslate();
  return (
    <TabbedForm {...props} warnWhenUnsavedChanges>
      <FormTab
        label={`${translate(`resources.${props.resource}.name`)} ${translate(
          "dietmo.information"
        )}`}
      >
        <TextInput source="name" />
        <CKEditorInput source="preparation" />

        <TextInput source="nameEn" label="nameEn" />
        <CKEditorInput source="preparationEn" label="preparationEn" />

        <FileInput format={formatFile} source="url" accept="video/*">
          <VideoField source="url" />
        </FileInput>

        <FileInput
          format={formatFile}
          source="srtUrl"
          accept=".srt"
          label="SRT "
        >
          <FileField source="url" title="SRT" />
        </FileInput>
        <FileInput
          format={formatFile}
          source="srtUrlEn"
          accept=".srt"
          label="SRT EN"
        >
          <FileField source="url" title="SRT" />
        </FileInput>

        <ColorInput source="color" />
        <ReferenceArrayInput source="categoriesIds" reference="RecipeCategory">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <BooleanInput source="isActive" />
        <BooleanInput source="isPrivate" />
        <NumberInput source="serving" />
        <ReferenceArrayInput
          label="Languages"
          reference="Language"
          source="languagesIds"
        >
          <SelectArrayInput optionText="code" />
        </ReferenceArrayInput>
        <ImageInput format={formatFile} source="imageUrl" accept="image/*">
          <ImageField source="url" />
        </ImageInput>
      </FormTab>
      <FormTab
        label={`${translate(`resources.RecipeIngredient.name`)}`}
        path="ingredients"
      >
        <ReferenceManyField
          addLabel={false}
          reference="RecipeIngredient"
          target="recipe"
        >
          <Datagrid>
            <TextField source="quantity" />
            <ReferenceField
              reference="MeasurementUnit"
              source="unit.id"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              reference="Ingredient"
              source="ingredient.id"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <EditButton />
            <DeleteButton redirect="" />
          </Datagrid>
        </ReferenceManyField>
        <AddButton relatedResource="RecipeIngredient" />
      </FormTab>
    </TabbedForm>
  );
};
export const RecipeEdit = (props) => (
  <Edit {...props}>
    <RecipeFormInput />
  </Edit>
);
export const RecipeCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput source="name" />
        <CKEditorInput source="preparation" />

        <TextInput source="nameEn" label="nameEN" />
        <CKEditorInput source="preparationEn" label="preparationEN" />

        <FileInput format={formatFile} source="url" accept="video/*">
          <VideoField source="url" />
        </FileInput>

        <FileInput
          format={formatFile}
          source="srtUrl"
          accept=".srt"
          label="SRT "
        >
          <FileField source="url" title="SRT" />
        </FileInput>

        <FileInput
          format={formatFile}
          source="srtUrlEn"
          accept=".srt"
          label="SRT EN"
        >
          <FileField source="url" title="SRT EN" />
        </FileInput>

        <ColorInput source="color" />
        <ReferenceArrayInput source="categoriesIds" reference="RecipeCategory">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <BooleanInput source="isActive" />
        <BooleanInput source="isPrivate" />
        <NumberInput source="serving" />
        
        <ReferenceArrayInput
          label="Languages"
          reference="Language"
          source="languagesIds"
        >
          <SelectArrayInput optionText="code" />
        </ReferenceArrayInput>
        <ImageInput format={formatFile} source="imageUrl" accept="image/*">
          <ImageField source="url" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
