import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";

import { useTranslate } from "react-admin";

const useStyles = makeStyles({
  button: {
    margin: "10px 24px",
    position: "relative",
  },
  iconPaddingStyle: {
    paddingRight: "0.5em",
  },
});

const BackButton = ({ redirect, label = "ra.action.back" }) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Button className={classes.button} component={Link} to={redirect}>
      <ArrowBackIcon className={classes.iconPaddingStyle} />
      {label && translate(label, { _: label })}
    </Button>
  );
};

BackButton.propTypes = {
  label: PropTypes.string,
  redirect: PropTypes.string.isRequired,
};

export default BackButton;
