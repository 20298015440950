import React from "react";
import {
  Datagrid,
  List,
  Create,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  SelectArrayInput,
  ReferenceArrayInput
} from "react-admin";
import Edit from "./components/Edit";
export const renderer = (record) =>
  `${record.name}`;

export const LanguageList = (props) => {
  
  return (
    <List {...props} >
      <Datagrid rowClick="edit">
        <TextField source="code" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
const LanguageFormInput = (props ) => {

  return (
    <SimpleForm {...props}>
    <TextInput source="id" disabled />
    <TextInput source="code" required />

    <ReferenceArrayInput
      
      reference="User"
      source="dieticianIds"
      sort={{ field: "email", order: "ASC" }}
      perPage={10}
      filterToQuery={(DIETICIAN) => ({
         role: "DIETICIAN" ,
      })}
    >
      <SelectArrayInput optionText="email" />
    </ReferenceArrayInput>



    <ReferenceArrayInput source="dietsIds" reference="Diet">
          <SelectArrayInput optionText={renderer} />
        </ReferenceArrayInput>


        <ReferenceArrayInput source="recipeIds" reference="Recipe"  perPage={500}>
          <SelectArrayInput optionText={renderer} />
        </ReferenceArrayInput>

    
  </SimpleForm>
  );
};
export const LanguageEdit = (props) => (
  <Edit {...props}>
    <LanguageFormInput />
  </Edit>
);
export const LanguageCreate = (props) => {
  // Read the dayId from the location which is injected by React Router and passed to our component by react-admin automatically
  
  return (
    <Create {...props}>
      <LanguageFormInput />
    </Create>
  );
};
