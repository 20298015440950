import React from "react";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  useTranslate,
  useCreate,
  useNotify,
  useRedirect,
  useGetManyReference,
} from "react-admin";
import { max, get, map } from "lodash";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const InstantAddButton = ({ classes, record, resource, relatedResource }) => {
  const translate = useTranslate();
  const [create, { loading }] = useCreate(relatedResource);
  const notify = useNotify();
  const days = useGetManyReference(
    "Day",
    "subDiet.id",
    record.id,
    { page: 1, perPage: 10 },
    { field: "createdAt", order: "DESC" },
    {},
    "SubDiet"
  );
  const maxDay = max(map(get(days, "data", []), (d) => d.day)) || 0;
  const redirect = useRedirect();
  const handleSubmit = async () => {
    create(
      {
        payload: {
          data: {
            day: maxDay + 1,
            subDietId: record.id,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          redirect("edit", "/Day", data.id);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      disabled={loading}
      onClick={handleSubmit}
      label={translate("dietmo.addResource", {
        resource: translate(`resources.${relatedResource}.name`),
      })}
    >
      <ChatBubbleIcon />
    </Button>
  );
};

export default withStyles(styles)(InstantAddButton);
