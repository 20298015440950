import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  BooleanField,
  BooleanInput,
  ImageInput,
  ImageField,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import CKEditorInput from "./components/CKEditorInput";
import Edit from "./components/Edit";
import { formatFile } from "../utils";
import get from "lodash/get";

export const TestimonialList = (props) => {
  return (
    <List
      {...props}
      filter={
        get(props, "permissions.role") !== "ADMIN"
          ? { dietician: { id: get(props, "permissions.id") } }
          : undefined
      }
    >
      <Datagrid rowClick="edit">
        <TextField source="title" label="Title" />
        <BooleanField source="isActive" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};
const TestimonialFormInput = ({ dieticianId: propDieticianId, ...props }) => {

  return (
    <SimpleForm
      {...props}
         warnWhenUnsavedChanges
    >
      <TextInput source="title" label="Title" />
      <TextInput source="titleEn" label="Title EN" />

      <CKEditorInput source="content" label="Content" />
      <CKEditorInput source="contentEn" label="İçerik EN" />

      <BooleanInput source="isActive" />


      <ReferenceArrayInput
          label="Languages"
          reference="Language"
          source="languagesIds"
        >
          <SelectArrayInput optionText="code" />
        </ReferenceArrayInput>

      <ImageInput format={formatFile} source="imageUrl" accept="image/*">
        <ImageField source="url" />
      </ImageInput>


      <TextInput source="authorName" label="authorName" />
      <TextInput source="authorLastname" label="authorLastname" />
      
      <ImageInput format={formatFile} source="authorImage" accept="image/*">
        <ImageField source="url" />
      </ImageInput>

    </SimpleForm>
  );
};
export const TestimonialEdit = (props) => (
  <Edit {...props}>
    <TestimonialFormInput />
  </Edit>
);

export const TestimonialCreate = (props) => {
  // Read the dayId from the location which is injected by React Router and passed to our component by react-admin automatically
  return (
    <Create {...props}>
      <TestimonialFormInput />
    </Create>
  );
};
